<template>
  <a-result status="404" title="404" sub-title="对不起, 当前页面走丢了。">
    <template #extra>
      <a-button @click="$router.push({path: '/'})" type="primary">
        返回首页
      </a-button>
    </template>
  </a-result>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>